import React from "react";
import Helmet from "react-helmet";
import Layout from "../components/layout";

const ManifestoPage = () => {
  return (
    <Layout>
      <Helmet>
        <title>NextStepHQ - Manifest</title>
        <meta name="description" content={"Our company manifesto"} />
      </Helmet>
      <div className="-contact">
        <div
          className="post-thumbnail"
          style={{
            backgroundImage: `url('/assets/alexander-andrews-HgUDpaGPTEA-unsplash.jpg')`,
            marginBottom: 0
          }}
        >
          <h1 className="post-title">Our Manifesto</h1>
          <p>We will always be defined by our values</p>
        </div>
        <div className="content-container">
          <p>
            This is a list of principles that guide NextStepHQ and all its
            members. The principles below cover various areas like People,
            Product, Marketing, etc.
          </p>
          <p>
            <i>Version 0.1</i>
          </p>

          <h2>Be Human.</h2>
          <p>Don't do anything you don't want someone else to do to you.</p>
          <h2>Transparency</h2>
          <p>Internal and external communication will be honest. </p>
          <p>
            A caveat for this will be our financial performance, which we won't
            share in the first few years.
          </p>
          <h2>Empathy & Observation.</h2>
          <p>
            Unless you have empathy you can't see & understand problems.
            Similarly, if you don't observe them, you can't completely
            understand their problem. They will describe symptoms, not the
            problem.
          </p>
          <h2>Honesty</h2>
          <p>
            We won’t lie about our features, use tactics to trick users,
            exaggerate to make ourselves seem more impressive, or pretend to be
            a company that we’re not. What you see is what you get.
          </p>

          <h2>The customer's customer is our customer.</h2>
          <p>Both us & our products should treat them as such.</p>

          <h2>Trust is everything</h2>
          <p>
            Trust is the greatest asset. Any behaviour that could break trust
            must be out of the question.
          </p>

          <h2>Everything is marketing</h2>
          <p>
            Each and every one of us is a marketer. Marketing is also the way we
            communicate product changes, the way we answer support, and the way
            we talk about our customers.
          </p>

          <h2>Be storytellers</h2>
          <p>
            Stories are easy to understand, share & relate to. It is an
            inherently human medium.{" "}
          </p>

          <h2>A “No” is more important than a “Yes”</h2>
          <p>
            We are going to remain a lean organisation. Saying yes to everything
            will lead to a mediocre product, unused features, and overall, a
            lack of value to our end user. Do a few great things well, rather
            than a lot of average things.{" "}
          </p>

          <h2>Be specific & concise, write it down.</h2>
          <p>
            Make it easy for people to do exactly what you want them to. Leave
            nothing to interpretation. Write it down. It will help in finding
            gaps in your thought process.{" "}
          </p>

          <h2>Debate: It bubbles the best idea.</h2>
          <p>
            If you think something is not right, don't be afraid to debate the
            topic. But never make it personal.
          </p>
          <p>
            We have heavily sourced ideas from BareMetrics <a href="https://baremetrics.com/blog/baremetrics-growth-manifesto" target="_blank">Growth Manifesto</a> by
            Corey Haines and Drift’s <a href="https://www.drift.com/blog/marketing-manifesto/" target="_blank">Marketing Manifesto</a> by Dave Gerhardt.{" "}
          </p>
          <p>
            This is a document which will continue to evolve, thus the version
            number.
          </p>
        </div>
      </div>
    </Layout>
  );
};
export default ManifestoPage;
